<template>
  <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M69.8705 37.6575L64.487 34.9665L61.655 23.6355C61.4885 22.968 60.8885 22.5 60.2 22.5H45.2C44.372 22.5 43.7 23.172 43.7 24V48H1.69995V49.5C1.69995 51.1575 3.04245 52.5 4.69995 52.5H69.2C70.028 52.5 70.7 51.828 70.7 51V39C70.7 38.4315 70.379 37.9125 69.8705 37.6575Z"
      fill="#22C55E"
      style="fill: #22c55e; fill: color(display-p3 0.1333 0.7725 0.3686); fill-opacity: 1" />
    <path d="M61.6535 36L59.0285 25.5H49.7V36H61.6535Z" fill="#E6E6E6" style="fill: #e6e6e6; fill: color(display-p3 0.902 0.902 0.902); fill-opacity: 1" />
    <path
      d="M1.69995 13.5V48H46.7V13.5C46.7 11.8425 45.3575 10.5 43.7 10.5H4.69995C3.04245 10.5 1.69995 11.8425 1.69995 13.5Z"
      fill="#4ADE80"
      style="fill: #4ade80; fill: color(display-p3 0.2902 0.8706 0.502); fill-opacity: 1" />
    <path
      d="M55.7 63C50.7365 63 46.7 58.9635 46.7 54C46.7 49.0365 50.7365 45 55.7 45C60.6635 45 64.7 49.0365 64.7 54C64.7 58.9635 60.6635 63 55.7 63Z"
      fill="#444444"
      style="fill: #444444; fill: color(display-p3 0.2667 0.2667 0.2667); fill-opacity: 1" />
    <path
      d="M55.7 58.5C53.219 58.5 51.2 56.481 51.2 54C51.2 51.519 53.219 49.5 55.7 49.5C58.181 49.5 60.2 51.519 60.2 54C60.2 56.481 58.181 58.5 55.7 58.5Z"
      fill="#E6E6E6"
      style="fill: #e6e6e6; fill: color(display-p3 0.902 0.902 0.902); fill-opacity: 1" />
    <path
      d="M15.2 63C10.2365 63 6.19995 58.9635 6.19995 54C6.19995 49.0365 10.2365 45 15.2 45C20.1635 45 24.2 49.0365 24.2 54C24.2 58.9635 20.1635 63 15.2 63Z"
      fill="#444444"
      style="fill: #444444; fill: color(display-p3 0.2667 0.2667 0.2667); fill-opacity: 1" />
    <path
      d="M15.2 58.5C12.719 58.5 10.7 56.481 10.7 54C10.7 51.519 12.719 49.5 15.2 49.5C17.681 49.5 19.7 51.519 19.7 54C19.7 56.481 17.681 58.5 15.2 58.5Z"
      fill="#E6E6E6"
      style="fill: #e6e6e6; fill: color(display-p3 0.902 0.902 0.902); fill-opacity: 1" />
    <path
      d="M70.7 49.5H69.2C68.372 49.5 67.7 48.828 67.7 48V45C67.7 44.172 68.372 43.5 69.2 43.5H70.7V49.5Z"
      fill="#EFD358"
      style="fill: #efd358; fill: color(display-p3 0.9373 0.8275 0.3451); fill-opacity: 1" />
  </svg>
</template>
